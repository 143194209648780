define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["de", {
    "appNames": {
      "1": {
        "name": "ACCOUNT",
        "presentationName": "ADDITIVE+ ACCOUNT"
      },
      "10": {
        "name": "MARKETING DASHBOARD",
        "presentationName": "ADDITIVE+ MARKETING DASHBOARD"
      },
      "100": {
        "name": "CMS",
        "presentationName": "ADDITIVE+ CMS"
      },
      "11": {
        "name": "ANFRAGEN",
        "presentationName": "ADDITIVE+ ANFRAGEN"
      },
      "12": {
        "name": "CRM",
        "presentationName": "ADDITIVE+ CRM"
      },
      "2": {
        "name": "GUTSCHEINE",
        "presentationName": "ADDITIVE+ GUTSCHEINE"
      },
      "4": {
        "name": "INHALTE",
        "presentationName": "ADDITIVE+ INHALTE"
      },
      "5": {
        "name": "MULTIMEDIA",
        "presentationName": "ADDITIVE+ MULTIMEDIA"
      },
      "50": {
        "name": "MARKETING AUTOMATION",
        "presentationName": "ADDITIVE+ MARKETING AUTOMATION"
      },
      "51": {
        "name": "MARKETING INSIGHTS",
        "presentationName": "ADDITIVE+ MARKETING INSIGHTS"
      },
      "6": {
        "name": "MAILER",
        "presentationName": "ADDITIVE+ MAILER"
      },
      "7": {
        "name": "NEWSLETTER",
        "presentationName": "ADDITIVE+ NEWSLETTER"
      },
      "8": {
        "name": "GÄSTE INTRANET",
        "presentationName": "ADDITIVE+ GÄSTE INTRANET"
      },
      "9": {
        "name": "JOURNAL",
        "presentationName": "ADDITIVE+ JOURNAL"
      }
    },
    "auditLog": {
      "deletedUser": "Gelöschter Benutzer",
      "description": "Hier werden die Aktivitäten aller Benutzer Ihrer Organisation innerhalb der App chronologisch aufgelistet.",
      "empty": "Keine Aktivitäten vorhanden",
      "entries": "Einträge",
      "page": "Seite",
      "time": "{time} Uhr",
      "title": "Aktivitätenprotokoll"
    },
    "breadcrumbs": {
      "demo": {
        "views": {
          "routeName": "Views",
          "simple-breadcrumb": {
            "routeName": "simple-breadcrumb"
          }
        }
      },
      "docs": {
        "components": {
          "routeName": "Components",
          "ui-breadcrumbs": {
            "routeName": "UI-Breadcrumbs"
          },
          "ui-button": {
            "routeName": "Buttons"
          },
          "ui-chart": {
            "routeName": "Charts"
          }
        },
        "routeName": "Docs"
      },
      "instance": {
        "reports": {
          "countries": {
            "routeName": "Länder"
          },
          "occupancy": {
            "routeName": "Auslastung"
          },
          "routeName": "Auswertungen"
        }
      }
    },
    "components": {
      "acrmNotifications": {
        "alertButtonText": {
          "guests-upload": "Zur Personen-Import-Liste"
        },
        "alertTypes": {
          "guests-upload": "Ihr Personen-Upload am {date} um {time} Uhr ist {warning, select, true {unvolltändig} other {fehlgeschlagen}}. Bitte versuchen Sie es erneut.",
          "reservations-upload": "Ihr Reservierungs-Upload am {date} um {time} Uhr ist {warning, select, true {unvolltändig} other {fehlgeschlagen}}. Bitte versuchen Sie es erneut.",
          "subscriber-upload": "Ihr Empfänger-Upload am {date} um {time} Uhr ist {warning, select, true {unvolltändig} other {fehlgeschlagen}}. Bitte versuchen Sie es erneut."
        }
      },
      "activityItem": {
        "showActivity": "Anzeigen"
      },
      "createDialog": {
        "title": "Was möchten Sie tun?"
      },
      "crmSettings": {
        "buildingCodes": {
          "dialog": {
            "add": "Hinzufügen",
            "description": "Geben Sie hier Häuser-Codes an, welche Sie beim Import von Reservierungen ausschließen möchten. Dadurch werden Reservierungen von anderen Häusern beim Import ignoriert und so Auslastungsinformationen wieder korrekt berechnet.",
            "label": "Häuser-Code",
            "title": "Häuser-Codes zum Ausschließen von Reservierungen"
          },
          "label": "Häuser-Codes zum Ausschließen von Reservierungen"
        }
      },
      "downloadDialog": {
        "dateRange": "Zeitraum",
        "description": "Wählen Sie die gewünschte Personenliste, welche Sie herunterladen möchten. Die CSV-Datei mit der gewählten Personenliste wird dann an Ihre E-Mail-Adresse gesendet.",
        "noDateRangeSelected": "Kein Zeitraum ausgewählt",
        "options": {
          "all": "Alle Personen herunterladen",
          "filtered": "Aktuell gefilterte Personen herunterladen",
          "leadExport": "Nur Lead-Kontakte herunterladen"
        },
        "selectDateRange": "Zeitraum auswählen",
        "title": "Personen-Download"
      },
      "fileImport": {
        "abortUpload": {
          "abort": "Abbrechen",
          "description": "Wollen Sie den Upload der Datei wirklich abbrechen?",
          "title": "Upload abbrechen"
        },
        "column": "Spalte",
        "description": "Ordnen Sie die Spalten den entsprechenden Merkmalen zu.",
        "emailError": "E-Mail-Adresse muss einer Spalte zugewiesen werden",
        "emptyError": {
          "description": "Die hochgeladene Datei hat keine Einträge. Bitte überprüfen Sie die Datei und laden Sie sie erneut hoch.",
          "title": "Ein Fehler ist aufgetreten"
        },
        "error": "{field} muss einer Spalte zugewiesen werden",
        "exclusionDialog": {
          "description": "Bitte beachten: Durch das Hochladen dieser Liste werden alle darin enthaltenen E-Mail-Adressen abgemeldet.<br><br>Diese Aktion ist unwiderruflich; auch bei einem Rückgängig-Machen des Imports bleiben die E-Mail-Adressen abgemeldet.<br><br>Um diese Adressen wieder anzumelden, muss der Double-Opt-In-Bestätigungsprozess abgeschlossen werden.<br><br>Es sollte sichergestellt werden, dass die Liste korrekt ist, bevor fortgefahren wird.",
          "inputLabel": "Dateiname",
          "title": "Achtung!"
        },
        "import": "Importieren",
        "noImport": "[nicht importieren]",
        "receiverConsentInfo": "Die Empfänger müssen ihr Einverständnis für die Eintragung in das Adressbuch und für die Zusendung von E-Mails erteilt haben.",
        "requiredFields": "Felder zu {fields} sind verpflichtend.",
        "rowCount": "Es {count, plural, =1 {wurde 1 Eintrag} other {wurden # Einträge}} gefunden.",
        "rowCountIncorrect": "Es wurden > {count} Einträge gefunden.",
        "setSourceName": "Import Label setzen",
        "sourceName": "Import Label",
        "sourceNameInfo": "Aktivieren Sie diese Option, um Imports mit einem Label zu kennzeichnen, damit vergangene oder zukünftige Imports zusammengeführt und Daten verbunden werden können.",
        "title": "Import von \"{fileName}\"",
        "toast": {
          "processingUpload": "Upload wird verarbeitet"
        },
        "typeError": {
          "description": "Es dürfen nur Dateien vom Typ \".csv\" oder \".xlsx\" hochgeladen werden.",
          "title": "Ein Fehler ist aufgetreten"
        },
        "uploadAlreadyRunning": {
          "continue": "Fortfahren",
          "description": "Es wird bereits eine Datei hochgeladen. Wenn Sie einen neuen Upload starten, wird der aktuelle Upload abgebrochen. Wollen Sie den bestehenden Upload der Datei wirklich abbrechen und einen neuen Upload starten?",
          "title": "Upload abbrechen"
        }
      },
      "importDetailNavdrawer": {
        "importCount": "{count, plural, =1 {1 Import} other {# Importe}}",
        "item": {
          "descriptionGuests": "Von {rowCount, plural, =1 {1 Eintrag} other {# Einträgen}} {affectedCount, plural, =1 {wurde 1 Kontakt} other {wurden # Kontakte}} importiert.",
          "descriptionLeads": "Von {rowCount, plural, =1 {1 Eintrag} other {# Einträgen}} {affectedCount, plural, =1 {wurde 1 Lead} other {wurden # Leads}} importiert.",
          "descriptionReservations": "Von {rowCount, plural, =1 {1 Eintrag} other {# Einträgen}} {affectedCount, plural, =1 {wurde 1 Reservierung} other {wurden # Reservierungen}} importiert.",
          "importFromPMS": "Import aus Hotelsoftware",
          "importTime": "{time} Uhr",
          "importing": {
            "descriptionGuests": "{rowCount, plural, =1 {1 Kontakt wird} other {# Kontakte werden}} importiert.",
            "descriptionLeads": "{rowCount, plural, =1 {1 Lead wird} other {# Leads werden}} importiert.",
            "descriptionReservations": "{rowCount, plural, =1 {1 Reservierung wird} other {# Reservierungen werden}} importiert.",
            "label": "Import läuft..."
          },
          "manualImport": "Manueller Import",
          "metaLeadsImport": "Meta Leads Import",
          "pms": "PMS",
          "undo": {
            "confirmDialog": {
              "description": "Wenn Sie den Import rückgängig machen, werden die jeweiligen Kontakte nicht automatisch wieder zu den Marketing-Kampagnen angemeldet. Soll der Import rückgängig gemacht werden?",
              "title": "Achtung",
              "undo": "Rückgängig machen"
            },
            "errorDialog": {
              "confirm": "OK",
              "description": "Es wird bereits ein Import rückgängig gemacht. Bitte versuchen Sie es etwas später erneut!",
              "title": "Fehler"
            },
            "successDialog": {
              "description": "Der Import wird rückgängig gemacht. Dies kann einige Minuten dauern.",
              "title": "Erfolg"
            },
            "text": "Rückgängig machen"
          }
        },
        "noImport": "Es wurde noch kein Import durchgeführt.",
        "pmsSynchronization": {
          "deletedPersons": "Gelöschte Personen:  <b>{count}</b>",
          "deletedReservations": "Gelöschte Reservierungen: <b>{count}</b>",
          "importedPersons": "Importierte Personen: <b>{count}</b>",
          "importedReservations": "Importierte Reservierungen: <b>{count}</b>",
          "loadingText": "Synchronisation mit der Hotelsoftware wird durchgeführt...",
          "successText": "Die letzte erfolgreiche Synchronisation mit der Hotelsoftware wurde am <b>{date}</b> um <b>{time}</b> durchgeführt."
        },
        "title": "Informationen"
      },
      "reportTable": {
        "noDataPlaceholder": "k.A.",
        "notEnoughData": "Nicht genügend Daten"
      }
    },
    "dashboard": {
      "description": "Hier erhalten Sie einen schnellen Überblick über wichtige Kennzahlen zu Personen und deren Aktivitäten.",
      "keyIndicators": {
        "personCount": "Personen insgesamt",
        "reservationCount": {
          "monthly": "Reservierungen im {date}",
          "yearly": "Reservierungen im Jahr {year}",
          "yearlyTooltip": "Beinhaltet alle Reservierungen, die innerhalb des aktuellen Jahres getätigt wurden."
        }
      },
      "latestActivities": {
        "description": "Die nachfolgende Liste zeigt die aktuellsten Aktivitäten (Abschlüsse, Anfragen, Käufe, Bestellungen) Ihrer Kontakte, Kunden und Gäste.",
        "empty": "Es gibt noch keine Aktivitäten.",
        "filter": {
          "all": "Alle Aktivitäten",
          "enquiry": "Anfragen",
          "facebook": "Facebook",
          "label": "Aktivitätstyp",
          "marketingCloud": "MARKETING AUTOMATION",
          "newsletter": "Newsletter",
          "reservation": "Reservierungen",
          "survey": "Umfragen",
          "tiktok": "TikTok",
          "voucher": "Gutscheine"
        },
        "placeholders": {
          "guest": "Gast"
        },
        "reservations": {
          "pmsId": "(PMS-ID: {pmsId})"
        },
        "time": "{time} Uhr",
        "title": "Letzte Aktivitäten"
      },
      "latestReservationActivities": {
        "description": "Die nachfolgende Liste zeigt die aktuellsten Reservierungsaktivitäten (Reservierungen, Stornierungen, Anfragen, Optionen) Ihrer Kontakte, Kunden und Gäste. Die Daten stammen aus dem verbundenen PMS.",
        "title": "Letzte Reservierungsaktivitäten"
      },
      "salutations": {
        "afternoon": "Guten Nachmittag",
        "evening": "Guten Abend",
        "morning": "Guten Morgen"
      },
      "title": "Übersicht"
    },
    "dialogService": {
      "conflictError": {
        "dependencies": {
          "contentTypes": {
            "appIntegrations": "ADDITIVE+ APPS Integrationen",
            "campaigns": "Kampagnen",
            "landingPages": "Landingpages",
            "leadIntegrations": "Lead-Integrations",
            "pmsIntegrations": "Hotel Software Integrationen",
            "settings": "Einstellungen",
            "surveys": "Umfragen",
            "thirdPartyTokens": "ADDITIVE+ APPS Schnittstellen",
            "widgets": "Widgets"
          },
          "headers": {
            "app": "App",
            "content": "Inhalt",
            "contentType": "Inhaltstyp"
          }
        },
        "description": "Das Objekt kann nicht gelöscht werden, da es von anderen Inhalten verwendet wird. Lösen Sie die Abhängigkeiten bevor Sie das Objekt löschen.",
        "title": "Das Objekt ist in Verwendung"
      },
      "discardChanges": {
        "continueEditing": "Weiterarbeiten",
        "discardAction": "Verwerfen",
        "message": "Sollen ungespeicherte Änderungen verworfen werden?",
        "title": "Achtung!"
      },
      "error": {
        "message": "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut!",
        "title": "Etwas ist schief gelaufen"
      },
      "noPermission": {
        "message": "Sie haben leider keine Berechtigung um diese Aktion durchzuführen. Wenden Sie sich an Ihren Administrator.",
        "title": "Fehlende Berechtigung"
      }
    },
    "exclusionList": {
      "create": {
        "defaultImport": {
          "description": "Importieren Sie E-Mail-Adressen aus einer CSV- oder Excel-Datei.",
          "title": "Auszuschließende E-Mail-Adressen importieren"
        },
        "description": "Es kann entweder eine Liste mit E-Mail-Adressen hochgeladen oder die Adressen manuell einzeln zur Ausschlussliste hinzugefügt werden. Bitte beachten: Diese Aktion ist dauerhaft und kann nicht rückgängig gemacht werden.",
        "manualImport": "Manueller Import",
        "singleImport": {
          "description": "Fügen Sie E-Mail-Adressen manuell einzeln zur Ausschlussliste hinzu. Diese Aktion ist dauerhaft und kann nicht rückgängig gemacht werden. Wenn sich ein Benutzer erneut anmelden möchte, muss er dies über das Double-Opt-In-Verfahren bestätigen.",
          "title": "Auszuschließende E-Mail-Adressen manuell hinzufügen"
        },
        "title": "E-Mail-Adressen ausschließen"
      },
      "deleteDialog": {
        "description": "Wenn Sie die ausgewählten E-Mail-Adressen von der Ausschlussliste entfernen, werden sie nicht automatisch wieder für Marketing-E-Mails angemeldet.",
        "title": "E-Mail-Adressen von der Ausschlussliste entfernen"
      },
      "description": "In der Ausschlussliste können Personen eingetragen werden, die nicht für Marketingmaßnahmen kontaktiert werden sollen. E-Mail-Adressen können manuell über einen Import eingespielt werden.",
      "empty": "Keine ausgeschlossenen E-Mail-Adressen gefunden",
      "noMatch": "Ihre Filterung ergab keine Treffer.",
      "tableHeaderOptions": {
        "createdAt": "Erstellt am",
        "email": "E-Mail",
        "exclusionSource": "Ausschlussquelle",
        "exclusionType": "Ausschlusstyp"
      },
      "title": "Ausschlussliste"
    },
    "global": {
      "actions": {
        "activate": "Aktivieren",
        "change": "Ändern",
        "deactivate": "Deaktivieren",
        "delete": "Löschen",
        "disconnect": "Trennen",
        "dismissAlert": "Warnung nicht mehr anzeigen",
        "download": "Herunterladen",
        "exclude": "Ausschließen",
        "info": "Informationen",
        "learnMore": "mehr erfahren",
        "logout": "Abmelden",
        "more": "Mehr",
        "retry": "Erneut versuchen",
        "save": "Speichern",
        "unsubscribe": "Abmelden"
      },
      "dialogs": {
        "change": {
          "description": "Soll dieser Wert wirklich geändert werden?",
          "title": "Wert Ändern"
        }
      },
      "errors": {
        "email": "Ungültige E-Mail-Adresse",
        "positiveNumber": "Wert ist keine gültige positive Zahl",
        "presence": "Wert darf nicht leer sein"
      },
      "pages": "Seiten",
      "toasts": {
        "delete": {
          "error": "Ein Fehler ist aufgetreten",
          "success": "Erfolgreich gelöscht"
        },
        "error": "Ein unerwarteter Fehler ist aufgetreten",
        "loading": {
          "uploadImport": "Importliste wird hochgeladen"
        },
        "success": "Aktion erfolgreich durchgeführt",
        "update": {
          "actionLabel": "Neu laden",
          "description": "Ungespeicherte Änderungen gehen verloren.",
          "title": "Update verfügbar"
        }
      }
    },
    "languageSelect": {
      "add": "Sprache hinzufügen",
      "ar": "Arabisch",
      "de": "Deutsch",
      "en": "Englisch",
      "fr": "Französisch",
      "it": "Italienisch",
      "nl": "Niederländisch",
      "pl": "Polnisch",
      "remove": "Sprache entfernen",
      "removeDescription": "Sind Sie sicher diese Sprache entfernen zu wollen?",
      "ru": "Russisch"
    },
    "onboarding": {
      "1": {
        "description": "In Ihren Daten versteckt sich eine Fülle an Information. Das ADDITIVE+ CRM sammelt und analysiert Personen- und Reservierungsdaten sowie Conversions aus Ihren Online-Auftritten.",
        "title": "Nutzen Sie Ihre Daten!"
      },
      "2": {
        "description": "Ihre Gäste unterscheiden sich! Ansprache, Inhalt und Zeitpunkt Ihrer Kommunikation muss daran angepasst sein. ADDITIVE+ CRM interpretiert und segmentiert automatisch für Sie.",
        "title": "Automatische Segmentierung"
      },
      "3": {
        "description": "Nutzen Sie die Erkenntnisse aus der Vergangenheit für Ihre Entscheidungen. Die wichtigsten Kennzahlen aus Ihren Gäste- und Reservierungsdaten helfen Ihnen dabei.",
        "title": "Detaillierte Auswertungen"
      },
      "4": {
        "description": "Wie gut vorausgebucht ist Ihr Betrieb? Wie gut ist die Auslastung im Vergleich zu vergangenen Jahren? Erkennen Sie frühzeitig Notwendigkeiten für Ihr Marketing.",
        "title": "Auslastungsanalyse und -vorschau"
      }
    },
    "pageNotFound": {
      "button": "Zur Startseite",
      "content": "Leider konnte die gesuchte Seite nicht gefunden werden! <br>Entweder ist die URL nicht korrekt oder die Seite wurde entfernt bzw. umbenannt.",
      "title": "Seite wurde nicht gefunden!"
    },
    "people": {
      "activities": {
        "empty": "Es gibt noch keine Aktivitäten für diese Person",
        "title": "Aktivitäten"
      },
      "addresses": {
        "columns": {
          "email": "E-Mail-Adresse",
          "newsletter": "Newsletter"
        },
        "newsletterStates": {
          "activated": "Angemeldet",
          "deactivated": "Abgemeldet"
        },
        "popover": "Kontakt öffnen",
        "title": "Adressen"
      },
      "blocked": "Blockiert",
      "blockedAddress": "E-Mail blockiert",
      "create": {
        "importFromPMS": "Import aus Hotelsoftware",
        "instructionLink": "Wie müssen die Daten formatiert sein?",
        "leadsImport": "Leads Import",
        "manualImport": "Manueller Import",
        "metaLeads": {
          "description": "Importieren Sie Meta Leads aus einer CSV- oder Excel-Datei",
          "title": "Meta Leads importieren"
        },
        "persons": {
          "description": "Importieren Sie Personen aus einer CSV- oder Excel-Datei",
          "title": "Personen importieren"
        },
        "pms": {
          "persons": {
            "description": "Importieren Sie Personen aus einer CSV oder Excel-Datei",
            "title": "Importieren Sie Kontakte aus der Hotelsoftware"
          },
          "reservations": {
            "description": "Importieren Sie Reservierungen aus einer CSV- oder Excel-Datei",
            "title": "Importieren Sie Reservierungen aus der Hotelsoftware"
          },
          "synchronize": {
            "description": "Synchronisieren Sie Personen und Reservierungen mit der Hotelsoftware.",
            "error": {
              "import_in_progress": {
                "description": "Es läuft bereits ein Import. Bitte warten Sie, bis dieser abgeschlossen ist.",
                "title": "Import läuft bereits"
              },
              "pms_import_already_active": {
                "description": "Es braucht kein Import gestartet zu werden, da dies bereits automatisch passiert.",
                "title": "Import bereits aktiv"
              },
              "pms_not_active": {
                "description": "Die Hotelsoftware ist nicht aktiv. Bitte aktivieren Sie diese in den Einstellungen.",
                "title": "Hotelsoftware nicht aktiv"
              },
              "pms_not_supported": {
                "description": "Die Hotelsoftware wird nicht unterstützt. Bitte wählen Sie eine andere Hotelsoftware aus.",
                "title": "Hotelsoftware nicht unterstützt"
              }
            },
            "title": "Mit Hotelsoftware synchronisieren",
            "toastMessage": "Synchronisation läuft..."
          }
        },
        "synchronizePMS": "Synchronisieren"
      },
      "download": {
        "empty": {
          "description": "Der Export ist aufgrund von fehlenden bzw. nicht vorhandenen Daten nicht möglich.",
          "title": "Export nicht möglich"
        },
        "error": {
          "description": "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es etwas später erneut.",
          "title": "Unerwarteter Fehler"
        },
        "success": {
          "description": "Der Download der Personen wird vorbereitet. Die CSV-Datei mit der Personenliste wird in Kürze an die E-Mail-Adresse <b>{email}</b> gesendet.",
          "title": "Download wird vorbereitet"
        }
      },
      "empty": "Keine Personen gefunden",
      "informations": {
        "activated": "Aktiviert",
        "address": "Adresse",
        "birthDate": "Geburtstag",
        "deactivated": "Deaktiviert",
        "email": "E-Mail-Adresse",
        "fullName": "Name",
        "gender": "Geschlecht",
        "genderOptions": {
          "f": "Weiblich",
          "m": "Männlich",
          "null": "Keine Angabe",
          "o": "Anderes"
        },
        "language": "Sprache",
        "marketingAutomation": {
          "campaign": "Kampagne",
          "title": "ADDITIVE+ MARKETING AUTOMATION"
        },
        "nationality": "Nationalität",
        "newsletter": {
          "addressBook": "Adressbuch",
          "title": "ADDITIVE+ NEWSLETTER"
        },
        "noName": "Keine Daten",
        "pmsMarketingSettings": {
          "blocked": "Person blockiert",
          "lastUpdate": "Letzte Aktualisierung der Newsletteranmeldung",
          "newsletter": "Newsletter",
          "no": "Nein",
          "permissionMarketing": "Erlaubnis für Marketing",
          "subscribed": "Abonniert",
          "title": "Marketing Einstellungen Hotelsoftware",
          "unsubscribed": "Nicht abonniert",
          "yes": "Ja"
        },
        "state": "Status",
        "subscribed": "Angemeldet",
        "subscribedOn": "am {date}",
        "telephone": "Telefonnummer",
        "title": "Informationen",
        "unknown": "Unbekannt",
        "unsubscribed": "Abgemeldet",
        "vip": "VIP"
      },
      "metrics": {
        "reservationRevenue": {
          "revenueExtras": "Umsatz Extras",
          "revenueLogis": "Umsatz Unterkunft",
          "title": "Reservierungsumsatz"
        },
        "stays": {
          "helpText": "Zeigt effektive Aufenthalte + potentielle Aufenthalte durch Anfragen und Optionen an.",
          "nextNights": "Aktuelle und anstehende Übernachtungen",
          "nextStays": "Aktuelle und anstehende Aufenthalte",
          "pastNights": "Vergangene Übernachtungen",
          "pastStays": "Vergangene Aufenthalte"
        }
      },
      "noMatch": "Ihre Filterung ergab keine Treffer.",
      "notifications": {
        "birthday_today": "Hat heute Geburtstag",
        "birthday_tomorrow": "Hat morgen Geburtstag",
        "stay": "Befindet sich gerade im Haus"
      },
      "pms": "Hotelsoftware",
      "pmsLocked": "Blockiert durch PMS",
      "pmsSynchronizationNotification": {
        "buttonText": "Synchronisieren",
        "title": "Sie können Personen und Reservierungen mit Ihrer Hotelsoftware synchronisieren."
      },
      "reservationActivities": {
        "empty": "Es gibt noch keine Aufenthalte für diese Person",
        "possibleTypes": {
          "cancelled": "Stornierung",
          "option": "Option",
          "request": "Anfrage",
          "reservation": "Reservierung"
        },
        "tableColumns": {
          "booker": "Reservierer",
          "nights": "Nächte",
          "people": "Pers.",
          "pmsId": "PMS-ID",
          "reservationDate": {
            "description": "Erstellungsdatum des PMS-Objekts (\"source_created_at\")",
            "title": "Erstellt am"
          },
          "reservationType": "Typ",
          "revenueExtras": {
            "description": "Reservierungsumsatz Extras",
            "title": " RU Extras"
          },
          "revenueLogis": {
            "description": "Reservierungsumsatz Logis",
            "title": " RU Logis"
          },
          "timespan": "Zeitraum"
        },
        "title": "Aufenthalte"
      },
      "searchPlaceholder": "Suchen Sie nach Namen oder E-Mail-Adressen...",
      "segmentations": {
        "empty": "Für diese Person stehen leider noch keine Segmente zur Verfügung.",
        "error": "Ein unerwarteter Fehler ist aufgetreten.",
        "segments": {
          "age": "Alter",
          "asaAddressGroups": "ASA HOTEL Adressgruppen",
          "asaLastPreBooking": "ASA HOTEL letzte Vorausbuchung",
          "asaLastTravelGroup": "ASA HOTEL letzte Reisebegleitung",
          "asaLastTravelPurpose": "ASA HOTEL letzter Reisezweck",
          "asaLastTravelVehicle": "ASA HOTEL letztes Verkehrsmittel",
          "bookingLeadTime": "Buchungsvorlauf",
          "building": "Häuser",
          "facebookLeadCampaign": "Facebook-Lead-Kampagne",
          "favouriteTravelTime": "Bevorzugte Reisezeit",
          "interests": "Interessen",
          "lastStayDuration": "Länge des letzten Aufenthalts",
          "lastStayYear": "Letzter Aufenthalt",
          "onPageLeadCampaign": "A+ MARKETING AUTOMATION On-Page-Lead-Kampagne",
          "referralMarketingLeadCampaign": "A+ MARKETING AUTOMATION Weiterempfehlungs-Lead-Kampagne",
          "region": "Region",
          "stayDuration": "Aufenthaltsdauer",
          "stays": "Aufenthalte",
          "tiktokLeadCampaign": "TikTok-Lead-Kampagne",
          "travelMotivations": "Reisemotivation",
          "tripGroup": "Reisegruppe"
        },
        "title": "Segmente"
      },
      "title": "Personen",
      "unsubscribeDialog": {
        "description": "Die Person wird dadurch von allen Marketing-E-Mails abgemeldet und auf die Ausschlussliste gesetzt.",
        "title": "Person abmelden"
      }
    },
    "reports": {
      "ageGroupDistribution": {
        "title": "Gästeverteilung nach Altersgruppen",
        "tooltip": "Diese Tabelle zeigt die Verteilung aller Gäste des jeweiligen Jahres aufgrund ihres Alters."
      },
      "countries": {
        "collapsibleTable": {
          "error": "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es etwas später erneut."
        },
        "description": "In folgender Tabelle werden die Reservierungen (exkl. stornierte) aufgrund des Wohnsitzlandes der reservierenden Person analysiert. Die Reservierungen werden anhand des Anreisedatums dem jeweiligen Jahr zugeordnet.",
        "filter": {
          "ageGroup": {
            "label": "Alter der reservierenden Person",
            "placeholder": "Alle Altersgruppen"
          },
          "allAgeGroups": "Alle Altersgruppen",
          "allFrequencies": "Alle Länder",
          "frequency": {
            "label": "Frequenz",
            "placeholder": "Alle"
          },
          "period": {
            "label": "Zeitraum"
          }
        },
        "title": "Länder"
      },
      "countriesDistribution": {
        "title": "Gästeverteilung nach Wohnland",
        "tooltip": "Diese Tabelle zeigt die Verteilung aller Gäste des jeweiligen Jahres aufgrund des Wohnsitzlandes (nicht Nationalität) zum Zeitpunkt der Reservierung."
      },
      "cutOfDaysDistribution": {
        "table": {
          "average": "&empty; Anzahl Tage",
          "fiveToSixMonths": "121 - 180 Tage",
          "oneMonth": "0 - 30 Tage",
          "other": "Sonstige",
          "sixAndMoreMonths": "> 180 Tage",
          "threeToFourMonths": "61 - 120 Tage",
          "title": "Buchungsvorlauf",
          "twoMonths": "31 - 60 Tage"
        },
        "title": "Verteilung nach Buchungsvorlauf",
        "tooltip": "Diese Tabelle zeigt die Verteilung aller Reservierungen des jeweiligen Jahres aufgrund der Vorausbuchungsdauer der Reservierung. Dabei wird betrachtet, wieviele Tage vor der Anreise die Reservierung stattgefunden hat.<br/>Unter „Sonstige“ werden all jene Reservierungen angeführt, wo keine korrekte Vorausbuchungsdauer ermittelt werden kann (z.B. Erstelldatum der Reservierung nach der Anreise)."
      },
      "description": "In folgenden Tabellen werden die Reservierungen (exkl. stornierte) und deren Verteilung nach verschiedenen relevanten Kriterien betrachtet. Die Reservierungen werden anhand des Anreisedatums dem jeweiligen Jahr zugeordnet.",
      "filter": {
        "ageGroup": {
          "label": "Alter der reservierenden Person",
          "placeholder": "Alle"
        },
        "allAgeGroups": "Alle Altersgruppen",
        "allCountries": "Alle Länder",
        "allFrequencies": "Alle",
        "countries": {
          "label": "Wohnland der reservierenden Person",
          "placeholder": "Alle"
        }
      },
      "frequencyDistribution": {
        "table": {
          "firstTimer": "Erstbesucher",
          "frequenter": "Wiederkehrer",
          "regular": "Stammgäste",
          "reservationsCount": "Anzahl Reservierungen",
          "title": ""
        },
        "title": "Verteilung nach Frequenz",
        "tooltip": "Diese Tabelle zeigt die Verteilung aller Reservierungen des jeweiligen Jahres aufgrund der Buchungsfrequenz der reservierenden Person.<br/><br/>Erstbesucher: Die reservierende Person hat erstmalig eine Reservierung getätigt.<br/><br/>Wiederkehrer: Die reservierende Person hat bereits früher Reservierungen getätigt.<br/><br/>Stammgast: Die reservierende Person hat im Zeitraum der vorhergehenden 4 Kalenderjahre bis zum Zeitpunkt der Anreise dieser Reservierung min. 3 weitere Reservierungen getätigt."
      },
      "keyIndicators": {
        "table": {
          "arrivalsCount": "Ankünfte",
          "averageTurnover": "&empty; Res. Umsatz",
          "reservationsCount": "Reservierungen",
          "staysCount": "Nächtigungen",
          "title": "",
          "totalTurnover": "Umsatz in €"
        },
        "title": "Reservierungskennzahlen",
        "tooltip": "Diese Tabelle zeigt alle relevanten Kennzahlen zu den Reservierungen des jeweiligen Jahres."
      },
      "lengthOfStayDistribution": {
        "table": {
          "averageNights": "&empty; Anzahl Tage",
          "fourToSixNights": "4 - 6 Tage",
          "oneToThreeNights": "1 - 3 Tage",
          "sevenAndMoreNights": ">= 7 Tage",
          "title": "Aufenthaltsanalyse"
        },
        "title": "Verteilung nach Aufenthaltsdauer",
        "tooltip": "Diese Tabelle zeigt die Verteilung aller Reservierungen des jeweiligen Jahres aufgrund der Aufenthaltsdauer der Reservierung."
      },
      "occupancy": {
        "chart": {
          "cancellations": "aus Stornierungen",
          "filter": {
            "cancellations": "Stornierungen",
            "interval": "Auswertungsintervall",
            "intervalValues": {
              "monthly": "Monatlich",
              "weekly": "Wöchentlich"
            },
            "noReferenceYear": "Kein Referenzjahr",
            "options": "Zukünftige Optionen",
            "referenceDate": "Begrenzungsdatum",
            "referenceYear": "Referenzjahr",
            "years": "Jahre"
          },
          "intro": "Mit folgendem Analyse-Tool kann die Zimmerbelegung diverser Jahre auf Monats- und Wochenbasis miteinander verglichen werden. Mittels „Begrenzungsdatum“ kann die zukünftige Belegung zum Stand eines bestimmten Tages betrachtet werden. Das „Referenzjahr“ dient hierbei dazu, die final erreichte Belegung des gewählten Referenzjahrs im Diagramm zu visualisieren.",
          "options": "aus Optionen",
          "referenceYearLabel": "Referenzjahr {year}",
          "referenceYearLabelShort": "Ref. {year}",
          "reservations": "aus Reservierungen",
          "title": "Zimmerbelegung"
        },
        "description": "In folgenden Tabellen wird die Zimmerauslastung anhand der effektiven Zimmerbelegungsnächte aus den Reservierungen des jeweiligen Jahres ermittelt. Schließungszeiträume sowie Veränderungen an der Zimmeranzahl werden, sofern eingetragen, in den Berechnungen berücksichtigt.",
        "monthly": {
          "title": "Zimmerauslastung pro Monat",
          "tooltip": "Diese Tabelle zeigt Auslastungskennzahlen diverser Jahre auf Monatsbasis. Bei den Berechnungen werden die effektiven Öffnungstage sowie die effektiv vorhandene Zimmeranzahl verwendet.<br>1. Wert: Effektive Anzahl der belegten Zimmer an den effektiven Öffnungstagen des Monats (= Zimmerbelegungsnächte).<br>2. Wert: Prozentueller Anteil der Zimmerbelegungsnächte an den maximal möglich belegten Zimmern (Zimmerkapazität) an den effektiven Öffnungstagen des Monats (= Zimmerauslastung)."
        },
        "notification": {
          "button": "Zu den Einstellungen",
          "missingClosingPeriods": "Tragen Sie in den Einstellungen die Schließungszeiträume ein, damit diese für die Auswertungen berücksichtigt werden können.",
          "missingRoomsCount": "Tragen Sie in den Einstellungen die Zimmeranzahl ein, damit diese für die Auswertungen berücksichtigt werden kann.",
          "missingSettings": "Tragen Sie in den Einstellungen die Informationen zu Zimmeranzahl und Schließungszeiträume ein, damit diese für die Auswertungen berücksichtigt werden können."
        },
        "title": "Auslastung",
        "yearly": {
          "title": "Zimmerauslastung pro Jahr",
          "tooltip": "Diese Tabelle zeigt Auslastungskennzahlen auf Jahresbasis. Bei den Berechnungen werden die effektiven Öffnungstage sowie die effektiv vorhandene Zimmeranzahl verwendet.<br>Öffnungstage: Anzahl der Öffnungstage im jeweiligen Kalenderjahr. Schließungszeiträume, sofern eingetragen, und Schaltjahre werden berücksichtigt.<br>Zimmerkapazität: Anzahl der maximal möglichen belegten Zimmer eines Kalenderjahres. Laufende Veränderungen an der Zimmeranzahl werden berücksichtigt.<br>Zimmerbelegungsnächte: Effektive Anzahl der belegten Zimmer eines Kalenderjahres.<br>Zimmerauslastung: Prozentueller Anteil der Zimmerbelegungsnächte an der Zimmerkapazität."
        }
      },
      "reportCards": {
        "countries": {
          "description": "Zur Detail-Auswertung",
          "title": "Länder"
        },
        "occupancy": {
          "description": "Zur Detail-Auswertung",
          "title": "Auslastung"
        }
      },
      "title": "Auswertungen",
      "travelGroupDistribution": {
        "table": {
          "adultsWithChildren": "1 oder 2 Erw. mit Kind(ern)",
          "averagePersonCount": "&empty; Anzahl Personen",
          "group": "Gruppe",
          "oneAdult": "1 Erwachsene(r)",
          "other": "Sonstige",
          "title": "Reisegruppen",
          "twoAdults": "2 Erwachsene"
        },
        "title": "Verteilung nach Reisegruppen",
        "tooltip": "Diese Tabelle zeigt die Verteilung aller Reservierungen des jeweiligen Jahres aufgrund der Zusammensetzung der Reisegruppe der Reservierung.<br/><br/>1 Erwachsene(r): Die Reservierung gilt für 1 erwachsene Person.<br/><br/>2 Erwachsene: Die Reservierung gilt für 2 erwachsene Personen.<br/><br/>1 oder 2 Erw. mit Kind(ern): Die Reservierung gilt für 1 oder 2 erwachsene Personen mit min. einer minderjährigen (Kind oder Kleinkind aus PMS) Person.<br/><br/>Gruppe: Die Reservierung gilt für 3 oder mehr erwachsene Personen mit oder ohne minderjährige (Kind oder Kleinkind aus PMS) Person(en).<br/><br/>Sonstige: Die Reservierung gilt für eine Personenkonstellation außerhalb der anderen Kategorien."
      }
    },
    "routes": {
      "auditLog": {
        "title": "Aktivitätenprotokoll"
      },
      "dashboard": {
        "title": "Übersicht"
      },
      "exclusionList": {
        "title": "Ausschlussliste"
      },
      "help": {
        "title": "Hilfe und Support"
      },
      "people": {
        "activities": {
          "title": "Aktivitäten"
        },
        "title": "Personen"
      },
      "reports": {
        "countries": {
          "title": "Länder"
        },
        "occupancy": {
          "title": "Auslastung"
        },
        "title": "Auswertungen"
      },
      "settings": {
        "integrations": {
          "empty": {
            "button": "Hotelsoftware anlegen",
            "info": "Keine Hotelsoftware definiert. Um den Datentransfer zu den Apps herzustellen, müssen Sie zunächst im ADDITIVE+ ACCOUNT Ihre Hotelsoftware angeben. Alternativ können Sie die Daten im Menüpunkt \"Personen\" auch manuell importieren, ohne eine Verbindung zu Ihrer Hotelsoftware herstellen zu müssen."
          },
          "title": "Integrationen"
        },
        "title": "Einstellungen"
      }
    },
    "select": {
      "empty": "Keine Optionen gefunden",
      "emptyOption": "Keine Auswahl",
      "noResults": "Keine Ergebnisse gefunden",
      "searchPlaceholder": "Suche"
    },
    "selectContainer": {
      "items": "{count, plural, =1 {# Element} other {# Elemente}} ausgewählt"
    },
    "settings": {
      "connection": {
        "aida": {
          "description": "Gästedaten und Reservierungen direkt aus Aida importieren",
          "title": "Aida Datenimport"
        },
        "apaleo": {
          "description": "Gästedaten und Reservierungen direkt aus Apaleo importieren",
          "title": "Apaleo Datenimport"
        },
        "asa": {
          "description": "Gästedaten und Reservierungen direkt aus ASA HOTEL importieren",
          "instructions": {
            "description": "Die Schnittstelle ist bereit für Imports aus ASA HOTEL und nimmt diese entgegen.<br/>Um den Datenimport in ASA HOTEL einzurichten, folgen Sie bitte der Anleitung.",
            "download": "Anleitung herunterladen",
            "title": "Import aktiviert"
          },
          "title": "ASA HOTEL Datenimport"
        },
        "casablanca": {
          "description": "Gästedaten und Reservierungen direkt aus Casablanca importieren",
          "title": "Casablanca Datenimport"
        },
        "elite": {
          "description": "Gästedaten und Reservierungen direkt aus Elite importieren",
          "title": "Elite Datenimport"
        },
        "fidelio": {
          "description": "Gästedaten und Reservierungen direkt aus Fidelio importieren",
          "title": "Fidelio Datenimport"
        },
        "gastrodat": {
          "description": "Gästedaten und Reservierungen direkt aus Gastrodat importieren",
          "title": "Gastrodat Datenimport"
        },
        "guestline": {
          "description": "Gästedaten und Reservierungen direkt aus Guestline importieren",
          "title": "Guestline Datenimport"
        },
        "hotel_felix": {
          "description": "Gästedaten und Reservierungen direkt aus Hotel Felix importieren",
          "title": "Hotel Felix Datenimport"
        },
        "hugo": {
          "description": "Gästedaten und Reservierungen direkt aus Hugo importieren",
          "title": "Hugo Datenimport"
        },
        "mews": {
          "description": "Gästedaten und Reservierungen direkt aus Mews importieren",
          "title": "Mews Datenimport"
        },
        "protel": {
          "description": "Gästedaten und Reservierungen direkt aus Protel importieren",
          "title": "Protel Datenimport"
        },
        "si_hot": {
          "description": "Gästedaten und Reservierungen direkt aus Sihot importieren",
          "title": "Sihot Datenimport"
        },
        "status": {
          "false": {
            "message": "Letzter Import: {date}",
            "noImportMessage": "Es wurde noch kein Import durchgeführt.",
            "state": "error"
          },
          "true": {
            "message": "Letzter Import: {date}",
            "state": "success"
          },
          "undefined": {
            "message": "Import nicht eingerichtet",
            "state": ""
          }
        },
        "warning": {
          "action": "JA, deaktivieren",
          "description": "Sind Sie sicher, dass der Import aus ASA HOTEL deaktiviert werden soll?",
          "title": "Import deaktivieren"
        },
        "win_hotel": {
          "description": "Gästedaten und Reservierungen direkt aus WinHotel importieren",
          "title": "WinHotel Datenimport"
        }
      },
      "facebookLeads": {
        "facebookOAuth": {
          "connect": "Verbinden",
          "connectedPopover": "Nutzer {user} verbunden",
          "disconnect": "Verbindung trennen",
          "disconnectDialog": {
            "description": "Soll die Verbindung zu Ihrem Facebook-Account wirklich getrennt werden?",
            "title": "Verbindung trennen"
          },
          "disconnectedPopover": "Nicht verbunden",
          "facebookConnect": {
            "description": "Mit eigenem Facebook-Account verbinden",
            "title": "Mit Facebook-Account verbinden"
          },
          "label": "Facebook",
          "loginAbortDialog": {
            "description": "Sind Sie sicher, dass Sie die Einstellungen verwerfen wollen? Dadurch wird Ihr Facebook-Account nicht verbunden.",
            "title": "Einstellungen verwerfen"
          },
          "systemUser": {
            "description": "Verwendet den Standard ADDITIVE+ System-User",
            "title": "Als System-User verbinden"
          }
        },
        "facebookPage": {
          "label": "Facebook-Seite"
        },
        "importLeads": "Facebook-Leads importieren",
        "label": "Import von Facebook-Leads",
        "missingPermissionsDialog": {
          "description": "Bitte aktivieren Sie im folgenden Fenster alle angeforderten Berechtigungen. Ohne diese kann der Facebook-Lead-Import nicht aktiviert werden.",
          "error": "Es wurden nicht alle benötigten Berechtigungen aktiviert.",
          "title": "Berechtigungen fehlen"
        }
      },
      "import": {
        "skipDeletedReservationsImport": {
          "description": "Die mittels Datenimport als \"gelöscht\" markierten Reservierungen bleiben im ADDITIVE+ CRM gespeichert.",
          "label": "Gelöschte Reservierungen behalten",
          "modalDescription": "Sollen die als \"gelöscht\" markierten Reservierungen beim Import {active, select, true { aus dem ADDITIVE+ CRM gelöscht werden} other { im ADDITIVE+ CRM gespeichert bleiben}}?",
          "modalTitle": "Gelöschte Reservierungen behalten",
          "warning": "ACHTUNG: Diese Einstellung sollte nur in Ausnahmefällen nötig sein!"
        },
        "title": "Ausnahmen"
      },
      "tiktokLeads": {
        "error": {
          "description": "Es ist ein Fehler aufgetreten. Bitte melden Sie sich erneut mit Ihrem TikTok Account an.",
          "title": "Fehler"
        },
        "importLeads": "TikTok-Leads importieren",
        "label": "Import von TikTok-Leads",
        "noAdAccountsInfo": "Es wurden keine Anzeigekonten gefunden. Um Leads importieren zu können, muss im TikTok Business Center mindestens ein Anzeigekonto erstellt werden.",
        "tiktokAdAccount": {
          "label": "TikTok-Anzeigekonto"
        },
        "tiktokOAuth": {
          "connect": "Verbinden",
          "connectedPopover": "Nutzer {user} verbunden",
          "disconnect": "Verbindung trennen",
          "disconnectDialog": {
            "description": "Soll die Verbindung zu Ihrem TikTok-Account wirklich getrennt werden?",
            "title": "Verbindung trennen"
          },
          "disconnectedPopover": "Nicht verbunden",
          "label": "TikTok",
          "loading": "Verbindung wird hergestellt..."
        }
      }
    },
    "toast": {
      "success": "Aktion war erfolgreich",
      "unexpectedError": "Ein unerwarteter Fehler ist aufgetreten."
    },
    "toasts": {
      "loading": {
        "description": "Etwas Geduld bitte, wir sind fast fertig!",
        "title": "Das dauert länger als erwartet."
      }
    },
    "uiActions": {
      "back": "Zurück",
      "close": "Schließen",
      "confirm": "Bestätigen",
      "delete": "Löschen",
      "edit": "Bearbeiten",
      "export": "Exportieren",
      "info": "Informationen",
      "menu": "Menü",
      "more": "Mehr",
      "reset": "Zurücksetzen",
      "save": "Speichern",
      "search": "Suchen"
    },
    "uiApplicationNavigationDrawer": {
      "accountSwitch": {
        "empty": "Keine Organisation gefunden",
        "organizationSettings": "Organisationseinstellungen",
        "searchPlaceholder": "Suchen"
      },
      "appsList": {
        "goToApps": "zu den Apps",
        "myApps": "Meine Apps",
        "planNames": {
          "10": {
            "starter_yearly": "Standard"
          },
          "12": {
            "professional_yearly": "Segmentation",
            "starter_yearly": "Customer Data Platform"
          },
          "50": {
            "enterprise_yearly": "MARKETING CLOUD",
            "professional_yearly": "AUTOMATION CLOUD",
            "starter_yearly": "LEAD CLOUD"
          },
          "51": {
            "professional_yearly": "Attribution",
            "starter_yearly": "Tracking"
          },
          "default": {
            "enterprise_monthly": "Enterprise",
            "enterprise_yearly": "Enterprise",
            "professional_monthly": "Professional",
            "professional_yearly": "Professional",
            "standard": "Standard",
            "starter_monthly": "Starter",
            "starter_yearly": "Starter"
          }
        },
        "showMoreApps": "Weitere Apps entdecken"
      }
    },
    "uiCategoryCard": {
      "contentCountError": "Diese Kategorie kann nicht gelöscht werden, da sie noch Inhalte enthält.",
      "titleError": "Achtung!"
    },
    "uiCollapsibleTable": {
      "collapsibleRowDescription": "{count, plural, =1 {# Unterkategorie} other {# Unterkategorien}}"
    },
    "uiColorComposition": {
      "dialog": {
        "preview": "Vorschau",
        "save": "Speichern"
      }
    },
    "uiContentCount": {
      "count": "{count, plural, =1 {# Eintrag} other {# Einträge}}",
      "filterCount": "{count, plural, =1 {# Eintrag} other {# Einträge}} gefunden",
      "pages": "Seite <span class=\"primary ph05\">{page}</span> / <span class=\"pl05\">{total}</span>"
    },
    "uiContentInfo": {
      "showLess": "Weniger anzeigen",
      "showMore": "Mehr anzeigen"
    },
    "uiDateFilter": {
      "label": "Betrachtungszeitraum"
    },
    "uiDatePicker": {
      "filters": {
        "custom_date": "Benutzerdefiniert",
        "last_month": "Letzter Monat",
        "last_thirty_days": "Letzte 30 Tage",
        "last_week": "Letzte Woche",
        "this_month": "Dieser Monat",
        "this_week": "Diese Woche"
      },
      "weekDaysShort": {
        "Fri": "Fr",
        "Mon": "Mo",
        "Sat": "Sa",
        "Sun": "So",
        "Thu": "Do",
        "Tue": "Di",
        "Wed": "Mi"
      }
    },
    "uiDatetimePicker": {
      "date": "Datum",
      "endDate": "Enddatum",
      "endTime": "Enduhrzeit",
      "error": {
        "beforeToday": "Vergangenes Datum",
        "false": "Ungültiges Datum"
      },
      "startDate": "Startdatum",
      "startTime": "Startuhrzeit",
      "time": "Uhrzeit"
    },
    "uiDiscardChanges": {
      "discardAction": "Verwerfen",
      "message": "Sollen ungespeicherte Änderungen verworfen werden?",
      "title": "Achtung!"
    },
    "uiEditor": {
      "actions": {
        "bold": "Fett",
        "bulletList": "Aufzählungsliste",
        "code": "Code",
        "headings": {
          "h1": "Überschrift 1",
          "h2": "Überschrift 2",
          "h3": "Überschrift 3",
          "h4": "Überschrift 4",
          "p": "Normal"
        },
        "headingsShort": {
          "h1": "H1",
          "h2": "H2",
          "h3": "H3",
          "h4": "H4",
          "p": "P"
        },
        "horizontalRule": "Trennlinie",
        "italic": "Kursiv",
        "link": "Link",
        "numberedList": "Numerierte Liste",
        "quote": "Zitat",
        "underline": "Unterstrichen"
      },
      "urlDialog": {
        "openInNewTab": "In neuem Tab öffnen",
        "save": "Speichern",
        "text": "Text",
        "type": {
          "name": "Link-Typ",
          "options": {
            "email": "E-Mail",
            "tel": "Telefon",
            "weblink": "Weblink"
          }
        },
        "url": "URL",
        "urlNotValid": {
          "email": "Diese E-Mail-Adresse ist nicht gültig",
          "tel": "Diese Telefonnummer ist nicht gültig",
          "weblink": "Diese URL ist nicht gültig"
        }
      }
    },
    "uiFilterInputs": {
      "buttonText": "Filtern",
      "daterange": {
        "end": "bis",
        "start": "von"
      }
    },
    "uiFormControl": {
      "recommendedLengthWarning": "Wert überschreitet die empfohlene Länge von {recommendedLength} Zeichen"
    },
    "uiFormDialog": {
      "save": "Speichern"
    },
    "uiListView": {
      "bulkDelete": "{count, plural, =1 {Ein Element} other {# Elemente}} gelöscht",
      "common": {
        "bulkDelete": {
          "action": "Alle löschen",
          "description": "Die ausgewählten Inhalte werden dadurch unwiederruflich gelöscht und können nicht mehr wiederhergestellt werden.<br/>Sollen die ausgewählten Inhalte wirklich gelöscht werden?"
        },
        "deleteContent": {
          "description": "Der Inhalt wird dadurch unwiederruflich gelöscht und kann nicht mehr wiederhergestellt werden.<br/>Soll <span class=\"font-medium\">\"{name}\"</span> wirklich gelöscht werden?",
          "title": "Achtung!"
        }
      },
      "delete": "Löschen",
      "empty": "Es gibt noch keine Inhalte.<br/>Klicken Sie auf den untenstehenden Button um einen Inhalt zu erstellen.",
      "error": "Ein unerwarteter Fehler ist aufgetreten.",
      "errors": {
        "rate_in_use": "Diese Rate kann nicht gelöscht werden, da Sie in einem Preiszeitraum verwendet wird.",
        "title": "Achtung!",
        "used_in_post": "Diese Person kann nicht gelöscht werden, da sie in einem Beitrag verwendet wird.",
        "vouchers_left": "Dieser Inhalt kann nicht gelöscht werden, da er in einem Gutschein verwendet wird."
      },
      "noMatch": "Ihre Filterung ergab keine Treffer.",
      "properties": {
        "published": "öffentlich",
        "unpublished": "nicht öffentlich"
      }
    },
    "uiMonthDayPicker": {
      "day": "Tag",
      "month": "Monat"
    },
    "uiNavigationBarFilterabel": {
      "dynamicFilters": {
        "buttonText": "Weitere Filter",
        "error": {
          "retryButtonText": "Es ist ein Fehler aufgetreten! Jetzt neu versuchen"
        },
        "navigationDrawer": {
          "title": "Weitere Filter"
        }
      },
      "searchInputDefaultPlaceholder": "Durchsuchen"
    },
    "uiOnboarding": {
      "actions": {
        "back": "Zurück",
        "endOnboarding": "Jetzt loslegen",
        "next": "Weiter",
        "skip": "Überspringen"
      }
    },
    "uiPublishResource": {
      "description": "Wählen Sie hier aus welche Sprachen Sie veröffentlichen möchten. Zudem können Sie den Rahmen der Veröffentlichung zeitlich einschränken.",
      "descriptionNoLang": "Wählen Sie den Zeitpunkt für die Veröffentlichung aus.",
      "errors": {
        "after": "Muss nach dem Startdatum sein",
        "before": "Muss vor dem Enddatum sein",
        "required": "Darf nicht leer sein"
      },
      "languages": "Sprachen",
      "limitTime": "Veröffentlichungszeitraum einschränken",
      "save": "Speichern",
      "settings": "Einstellungen",
      "title": "Veröffentlichen"
    },
    "uiRangeSlider": {
      "errorMessage": "Der Wert muss eine positive Ganzzahl sein",
      "max": "max",
      "min": "min"
    },
    "uiTable": {
      "empty": "Keine Daten vorhanden",
      "error": "Ein Fehler ist aufgetreten",
      "retry": "Nochmal versuchen",
      "showLess": "Weniger anzeigen",
      "showMore": "Mehr anzeigen"
    },
    "uiTags": {
      "error": "Dieser Wert wurde bereits hinzugefügt",
      "interests": {
        "interests": "Interessen",
        "travelMotivation": "Reisemotive",
        "travelTime": "Reisezeiten"
      },
      "placeholder": "Tag hinzufügen",
      "tags": "Tags"
    },
    "uiTimepicker": {
      "errorMessage": "Ungültige Zeitangabe",
      "timeUnit": "Uhr"
    },
    "uiToast": {
      "success": "Aktion erfolgreich durchgeführt",
      "unexpectedError": "Ein unerwarteter Fehler ist aufgetreten"
    }
  }], ["en", {
    "appNames": {
      "1": {
        "name": "ACCOUNT",
        "presentationName": "ADDITIVE+ ACCOUNT"
      },
      "10": {
        "name": "MARKETING DASHBOARD",
        "presentationName": "ADDITIVE+ MARKETING DASHBOARD"
      },
      "100": {
        "name": "CMS",
        "presentationName": "ADDITIVE+ CMS"
      },
      "11": {
        "name": "INQUIRIES",
        "presentationName": "ADDITIVE+ INQUIRIES"
      },
      "12": {
        "name": "CRM",
        "presentationName": "ADDITIVE+ CRM"
      },
      "2": {
        "name": "VOUCHERS",
        "presentationName": "ADDITIVE+ VOUCHERS"
      },
      "4": {
        "name": "CONTENTS",
        "presentationName": "ADDITIVE+ CONTENTS"
      },
      "5": {
        "name": "MULTIMEDIA",
        "presentationName": "ADDITIVE+ MULTIMEDIA"
      },
      "50": {
        "name": "MARKETING AUTOMATION",
        "presentationName": "ADDITIVE+ MARKETING AUTOMATION"
      },
      "51": {
        "name": "MARKETING INSIGHTS",
        "presentationName": "ADDITIVE+ MARKETING INSIGHTS"
      },
      "6": {
        "name": "MAILER",
        "presentationName": "ADDITIVE+ MAILER"
      },
      "7": {
        "name": "NEWSLETTER",
        "presentationName": "ADDITIVE+ NEWSLETTER"
      },
      "8": {
        "name": "GUEST INTRANET",
        "presentationName": "ADDITIVE+ GUEST INTRANET"
      },
      "9": {
        "name": "JOURNAL",
        "presentationName": "ADDITIVE+ JOURNAL"
      }
    },
    "auditLog": {
      "deletedUser": "Deleted User",
      "description": "Find the chronological list of all your users' activities within the app below. Please note that the users must be part of your organization for you to see their activities.",
      "empty": "No activities available",
      "entries": "Entries",
      "page": "Page",
      "time": "{time}",
      "title": "Activity Log"
    },
    "breadcrumbs": {
      "demo": {
        "views": {
          "routeName": "Views",
          "simple-breadcrumb": {
            "routeName": "simple-breadcrumb"
          }
        }
      },
      "docs": {
        "components": {
          "routeName": "Components",
          "ui-breadcrumbs": {
            "routeName": "UI-Breadcrumbs"
          },
          "ui-button": {
            "routeName": "Buttons"
          },
          "ui-chart": {
            "routeName": "Charts"
          }
        },
        "routeName": "Docs"
      },
      "instance": {
        "reports": {
          "countries": {
            "routeName": "Countries"
          },
          "occupancy": {
            "routeName": "Occupancy"
          },
          "routeName": "Reports"
        }
      }
    },
    "components": {
      "acrmNotifications": {
        "alertButtonText": {
          "guests-upload": "To guests import list"
        },
        "alertTypes": {
          "guests-upload": "The people upload on {date} at {time} was not successful. Please try again.",
          "reservations-upload": "The reservations upload on {date} at {time} was not successful. Please try again.",
          "subscriber-upload": "The subscriber upload on {date} at {time} was not successful. Please try again."
        }
      },
      "activityItem": {
        "showActivity": "Show"
      },
      "createDialog": {
        "title": "What would you like to do?"
      },
      "crmSettings": {
        "buildingCodes": {
          "dialog": {
            "add": "Add",
            "description": "Please enter the facility codes whose corresponding reservations you would like to be ignored during the data import. This allows for a more relevant and correct data analysis of your occupancy.",
            "label": "Facility Code",
            "title": "Ignore reservations with certain facility codes"
          },
          "label": "Ignore reservations with certain facility codes"
        }
      },
      "downloadDialog": {
        "dateRange": "Time period",
        "description": "Select the desired list of people you would like to download. The CSV file with the selected list of people will then be sent to your email address.",
        "noDateRangeSelected": "No time period selected",
        "options": {
          "all": "Download all people",
          "filtered": "Download currently filtered people only",
          "leadExport": "Download lead contacts only"
        },
        "selectDateRange": "Select time period",
        "title": "Download people"
      },
      "fileImport": {
        "abortUpload": {
          "abort": "Cancel",
          "description": "Are you sure you want to cancel the upload?",
          "title": "Cancel upload"
        },
        "column": "Column",
        "description": "Assign the columns to the corresponding characteristics.",
        "emailError": "Email address must be assigned to a column.",
        "emptyError": {
          "description": "The uploaded file has no entries. Please check the file and try again.",
          "title": "An unexpected error occurred"
        },
        "error": "{field} must be assigned to a column.",
        "exclusionDialog": {
          "description": "Please note that uploading this list will unsubscribe all email addresses it contains.<br><br>This action is irreversible; even if you undo the import, the email addresses will remain unsubscribed.<br><br>To resubscribe these addresses, they will need to complete a double opt-in confirmation process.<br><br>Please ensure that this is the correct list before proceeding.",
          "inputLabel": "File name",
          "title": "Warning!"
        },
        "import": "Import",
        "noImport": "[do not import]",
        "receiverConsentInfo": "The subscribers must have given their consent to receive emails from you and to thus be entered into the address book.",
        "requiredFields": "The following fields are required: {fields}.",
        "rowCount": "{count, plural, =1 {1 entry was} other {# entries were}} found.",
        "rowCountIncorrect": "> {count} entries were found.",
        "setSourceName": "Set import label",
        "sourceName": "Import Label",
        "sourceNameInfo": "Enable this option to mark imports with a label so that past or future imports can be merged and data can be linked.",
        "title": "Importing \"{fileName}\"",
        "toast": {
          "processingUpload": "Upload is being processed"
        },
        "typeError": {
          "description": "Only \".csv\" or \".xlsx\" files may be uploaded.",
          "title": "An unexpected error occurred"
        },
        "uploadAlreadyRunning": {
          "continue": "Continue",
          "description": "A file is already being uploaded. Starting a new upload will cancel the current one. Are you sure you want to cancel the current upload to start a new one?",
          "title": "Cancel upload"
        }
      },
      "importDetailNavdrawer": {
        "importCount": "{count, plural, =1 {1 import} other {# imports}}",
        "item": {
          "descriptionGuests": "Out of {rowCount, plural, =1 {1 entry} other {# entries}}, {affectedCount, plural, =1 {1 contact has been} other {# contacts have been}} imported successfully.",
          "descriptionLeads": "Out of {rowCount, plural, =1 {1 entry} other {# entries}}, {affectedCount, plural, =1 {1 lead has been} other {# leads have been}} imported successfully.",
          "descriptionReservations": "Out of {rowCount, plural, =1 {1 entry} other {# entries}}, {affectedCount, plural, =1 {1 reservation has been} other {# reservations have been}} imported successfully.",
          "importFromPMS": "Import from hotel software",
          "importTime": "{time}",
          "importing": {
            "descriptionGuests": "{rowCount, plural, =1 {1 contact is} other {# contacts are}} being imported.",
            "descriptionLeads": "{rowCount, plural, =1 {1 lead is} other {# leads are}} being imported.",
            "descriptionReservations": "{rowCount, plural, =1 {1 reservation is} other {# reservations are}} being imported.",
            "label": "Preparing import..."
          },
          "manualImport": "Manual import",
          "metaLeadsImport": "Meta leads import",
          "pms": "PMS",
          "undo": {
            "confirmDialog": {
              "description": "If you undo the import, the respective contacts will not be automatically re-enrolled in the marketing campaigns. Should the import be undone?",
              "title": "Warning!",
              "undo": "Undo"
            },
            "errorDialog": {
              "confirm": "OK",
              "description": "We're already processing an import that is being undone. Please try again later!",
              "title": "Error"
            },
            "successDialog": {
              "description": "The import is being undone. This may take a few minutes.",
              "title": "Success!"
            },
            "text": "Undo"
          }
        },
        "noImport": "No import has been performed yet.",
        "pmsSynchronization": {
          "deletedPersons": "Deleted Persons:  <b>{count}</b>",
          "deletedReservations": "Deleted Reservations: <b>{count}</b>",
          "importedPersons": "Imported Persons: <b>{count}</b>",
          "importedReservations": "Imported Reservations: <b>{count}</b>",
          "loadingText": "Synchronization with the hotel software is in progress...",
          "successText": "The last successful synchronization with the hotel software was performed on <b>{date}</b> at <b>{time}</b>."
        },
        "title": "Information"
      },
      "reportTable": {
        "noDataPlaceholder": "N/A",
        "notEnoughData": "Not enough data"
      }
    },
    "dashboard": {
      "description": "Get a quick overview of the most important key figures about people and their activities.",
      "keyIndicators": {
        "personCount": "Total number of people",
        "reservationCount": {
          "monthly": "Reservations in {date}",
          "yearly": "Reservations in the year {year}",
          "yearlyTooltip": "Includes all reservations confirmed within the current year."
        }
      },
      "latestActivities": {
        "description": "The following list shows the latest activities such as inquiries, orders, purchases or reservations made by your contacts, customers and guests. The data comes from the connected PMS.",
        "empty": "There are no activities yet.",
        "filter": {
          "all": "All activities",
          "enquiry": "Inquiries",
          "facebook": "Facebook",
          "label": "Activity Type",
          "marketingCloud": "MARKETING AUTOMATION",
          "newsletter": "Newsletter",
          "reservation": "Reservations",
          "survey": "Surveys",
          "tiktok": "TikTok",
          "voucher": "Vouchers"
        },
        "placeholders": {
          "guest": "Guest"
        },
        "reservations": {
          "pmsId": "(PMS-ID: {pmsId})"
        },
        "time": "{time}",
        "title": "Last Activities"
      },
      "latestReservationActivities": {
        "description": "The following list shows the latest reservation activities such as reservations, cancellations, requests or options made by your contacts, customers and guests.",
        "title": "Last Reservation Activities"
      },
      "salutations": {
        "afternoon": "Good afternoon",
        "evening": "Good evening",
        "morning": "Good morning"
      },
      "title": "Dashboard"
    },
    "dialogService": {
      "conflictError": {
        "dependencies": {
          "contentTypes": {
            "appIntegrations": "ADDITIVE+ APPS integrations",
            "campaigns": "Campaigns",
            "landingPages": "Landing pages",
            "leadIntegrations": "Lead integrations",
            "pmsIntegrations": "PMS integrations",
            "settings": "Settings",
            "surveys": "Surveys",
            "thirdPartyTokens": "ADDITIVE+ APPS interfaces",
            "widgets": "Widgets"
          },
          "headers": {
            "app": "App",
            "content": "Content",
            "contentType": "Content Type"
          }
        },
        "description": "The object can not be deleted, because it is used by other contents. Make sure to resolve the remaining dependencies in order to delete this object.",
        "title": "The object is in use"
      },
      "discardChanges": {
        "continueEditing": "Continue editing",
        "discardAction": "Discard",
        "message": "Do you want to discard the unsaved changes?",
        "title": "Attention!"
      },
      "error": {
        "message": "An unexpected error has occurred. Please try again later.",
        "title": "Something went wrong"
      },
      "noPermission": {
        "message": "You do not have permission to perform this action. Please contact your administrator.",
        "title": "Missing permission"
      }
    },
    "exclusionList": {
      "create": {
        "defaultImport": {
          "description": "Import email addresses from a CSV or an Excel file.",
          "title": "Import email addresses to exclude"
        },
        "description": "You can either upload a list of email addresses or manually add them one by one for exclusion. Please note that this action is permanent and cannot be undone.",
        "manualImport": "Manual import",
        "singleImport": {
          "description": "Manually add email addresses to the exclusion list one by one. This action is permanent and cannot be undone. If a user wishes to resubscribe, they will need to confirm via double opt-in.",
          "title": "Add email addresses manually"
        },
        "title": "Exclude email addresses"
      },
      "deleteDialog": {
        "description": "If you remove the selected email addresses from the exclusion list, they will not be automatically re-subscribed to marketing emails.",
        "title": "Delete email addresses from exclusion list"
      },
      "description": "The exclusion list is used to specify email addresses that should not be contacted for marketing purposes. Email addresses can be manually imported.",
      "empty": "No excluded email addresses found",
      "noMatch": "Your filtering did not yield any results.",
      "tableHeaderOptions": {
        "createdAt": "Created at",
        "email": "Email",
        "exclusionSource": "Exclusion source",
        "exclusionType": "Exclusion type"
      },
      "title": "Exclusion List"
    },
    "global": {
      "actions": {
        "activate": "Enable",
        "change": "Change",
        "deactivate": "Disable",
        "delete": "Delete",
        "disconnect": "Disconnect",
        "dismissAlert": "Dismiss alert",
        "download": "Download",
        "exclude": "Exclude",
        "info": "Information",
        "learnMore": "Learn more",
        "logout": "Log out",
        "more": "More",
        "retry": "Retry",
        "save": "Save",
        "unsubscribe": "Unsubscribe"
      },
      "dialogs": {
        "change": {
          "description": "Are you sure you want to change this value?",
          "title": "Change value"
        }
      },
      "errors": {
        "email": "Invalid email address",
        "positiveNumber": "Value is not a valid positive number",
        "presence": "Value must not be empty"
      },
      "pages": "Pages",
      "toasts": {
        "delete": {
          "error": "An error has occurred",
          "success": "Successfully deleted"
        },
        "error": "An unexpected error has occurred",
        "loading": {
          "uploadImport": "Import list is being uploaded..."
        },
        "success": "Action successfully completed",
        "update": {
          "actionLabel": "Reload",
          "description": "Unsaved changes will be lost.",
          "title": "Update available"
        }
      }
    },
    "languageSelect": {
      "add": "Add language",
      "ar": "Arabic",
      "de": "German",
      "en": "English",
      "fr": "French",
      "it": "Italian",
      "nl": "Dutch",
      "pl": "Polish",
      "remove": "Remove language",
      "removeDescription": "Are you sure you want to remove this language?",
      "ru": "Russian"
    },
    "onboarding": {
      "1": {
        "description": "A wealth of information is hidden in your data. ADDITIVE+ CRM collects and analyzes guest and reservation data as well as conversions from your online presence.",
        "title": "Make use of the potential hidden in your data!"
      },
      "2": {
        "description": "When looking at your guests as a whole, it is important to acknowledge their differences! Dividing them into categories is therefore a prerequisite for any attractive and successful communication. ADDITIVE+ CRM does the hard work for you and provides you with a variety of different segments.",
        "title": "Automated Segmentation"
      },
      "3": {
        "description": "Use learnings and accrued knowledge from the past for your future decisions. The most important key figures from your guest and reservation data will help you do just that.",
        "title": "Detailed Analyses"
      },
      "4": {
        "description": "How well-booked is your facility? What is the average reservation lead time? How good is this year's occupancy compared to last year's or the year before? Recognize needs for marketing measures early on.",
        "title": "Occupancy Preview & Analysis"
      }
    },
    "pageNotFound": {
      "button": "Homepage",
      "content": "The page you were looking for could not be found!<br>The URL may be incorrect or the page may have been removed or renamed.",
      "title": "Page not found!"
    },
    "people": {
      "activities": {
        "empty": "There are no activities for this person yet.",
        "title": "Activities"
      },
      "addresses": {
        "columns": {
          "email": "Email Address",
          "newsletter": "Newsletter"
        },
        "newsletterStates": {
          "activated": "Subscribed",
          "deactivated": "Unsubscribed"
        },
        "popover": "Go to contact",
        "title": "Addresses"
      },
      "blocked": "Blocked",
      "blockedAddress": "Email blocked",
      "create": {
        "importFromPMS": "Import from hotel software",
        "instructionLink": "What data structure is required?",
        "leadsImport": "Leads import",
        "manualImport": "Manual import",
        "metaLeads": {
          "description": "Import Meta leads from a CSV or an Excel file",
          "title": "Import Meta leads"
        },
        "persons": {
          "description": "Import people from a CSV or an Excel file",
          "title": "Import people"
        },
        "pms": {
          "persons": {
            "description": "Import people from a CSV or an Excel file",
            "title": "Import contacts from your hotel software"
          },
          "reservations": {
            "description": "Import reservations from a CSV or an Excel file",
            "title": "Import reservations from your hotel software"
          },
          "synchronize": {
            "description": "Synchronize people and reservations with hotel software.",
            "error": {
              "import_in_progress": {
                "description": "There is already an import in progress. Please wait until it is finished.",
                "title": "Import in progress"
              },
              "pms_import_already_active": {
                "description": "No import necessary, because it already happens automatically.",
                "title": "Import already active"
              },
              "pms_not_active": {
                "description": "The hotel software you are using is not active. Please activate it in the settings.",
                "title": "Hotel software not active"
              },
              "pms_not_supported": {
                "description": "The hotel software you are using is not supported. Please choose another",
                "title": "Hotel software not supported"
              }
            },
            "title": "Synchronize with hotel software",
            "toastMessage": "Synchronization in progress..."
          }
        },
        "synchronizePMS": "Synchronize"
      },
      "download": {
        "empty": {
          "description": "The export is not possible due to missing or non-existent data.",
          "title": "Export not possible"
        },
        "error": {
          "description": "An unexpected error has occurred. Please try again later.",
          "title": "Unexpected error"
        },
        "success": {
          "description": "The download of your contacts is being prepared. The CSV file with the list of people will be sent shortly to the email address <b>{email}</b>.",
          "title": "Download is being prepared..."
        }
      },
      "empty": "No people found",
      "informations": {
        "activated": "Enabled",
        "address": "Address",
        "birthDate": "Birthday",
        "deactivated": "Disabled",
        "email": "Email Address",
        "fullName": "Name",
        "gender": "Gender",
        "genderOptions": {
          "f": "Female",
          "m": "Male",
          "null": "Not specified",
          "o": "Other"
        },
        "language": "Language",
        "marketingAutomation": {
          "campaign": "Campaign",
          "title": "ADDITIVE+ MARKETING AUTOMATION"
        },
        "nationality": "Nationality",
        "newsletter": {
          "addressBook": "Address Book",
          "title": "ADDITIVE+ NEWSLETTER"
        },
        "noName": "No data",
        "pmsMarketingSettings": {
          "blocked": "Blocked",
          "lastUpdate": "Last Update",
          "newsletter": "Newsletter",
          "no": "No",
          "permissionMarketing": "Permission for Marketing",
          "subscribed": "Subscribed",
          "title": "Hotel Software Marketing Settings",
          "unsubscribed": "Not subscribed",
          "yes": "Yes"
        },
        "state": "Status",
        "subscribed": "Subscribed",
        "subscribedOn": "on the {date}",
        "telephone": "Phone Number",
        "title": "Information",
        "unknown": "Unknown",
        "unsubscribed": "Unsubscribed",
        "vip": "VIP"
      },
      "metrics": {
        "reservationRevenue": {
          "revenueExtras": "Revenue extras",
          "revenueLogis": "Revenue accommodation",
          "title": "Reservation revenue"
        },
        "stays": {
          "helpText": "Indicates stays + potential stays from requests and options.",
          "nextNights": "Current and next nights",
          "nextStays": "Current and next stays",
          "pastNights": "Past nights",
          "pastStays": "Past stays"
        }
      },
      "noMatch": "Your filtering did not yield any results.",
      "notifications": {
        "birthday_today": "It's their birthday today!",
        "birthday_tomorrow": "It's their birthday tomorrow!",
        "stay": "Is currently staying with you!"
      },
      "pms": "Hotel Software",
      "pmsLocked": "Blocked through PMS",
      "pmsSynchronizationNotification": {
        "buttonText": "Synchronize",
        "title": "You can import and synchronize people and reservations with the data available in your hotel software."
      },
      "reservationActivities": {
        "empty": "Es gibt noch keine Aufenthalte für diese Person",
        "possibleTypes": {
          "cancelled": "Cancellation",
          "option": "Option",
          "request": "Request",
          "reservation": "Reservation"
        },
        "tableColumns": {
          "booker": "Booker",
          "nights": "Nights",
          "people": "People",
          "pmsId": "PMS-ID",
          "reservationDate": {
            "description": "Creation date of the PMS-Object (\"source_created_at\")",
            "title": "Created at"
          },
          "reservationType": "Type",
          "revenueExtras": {
            "description": "Revenue Extras",
            "title": "R. Extras"
          },
          "revenueLogis": {
            "description": "Revenue Logis",
            "title": "R. Logis"
          },
          "timespan": "Timespan"
        },
        "title": "Stays"
      },
      "searchPlaceholder": "Search by names or email addresses...",
      "segmentations": {
        "empty": "Unfortunately there are no segments available for this person.",
        "error": "An unexpected error has occurred",
        "segments": {
          "age": "Age",
          "asaAddressGroups": "ASA HOTEL Address Groups",
          "asaLastPreBooking": "ASA HOTEL Last Reservation Lead Time",
          "asaLastTravelGroup": "ASA HOTEL Last Travel Group",
          "asaLastTravelPurpose": "ASA HOTEL Last Travel Motive",
          "asaLastTravelVehicle": "ASA HOTEL Last Means of Transportation",
          "bookingLeadTime": "Booking Lead Time",
          "building": "Facilities",
          "facebookLeadCampaign": "Facebook Lead Campaign",
          "favouriteTravelTime": "Preferred Travel Time",
          "interests": "Interests",
          "lastStayDuration": "Length of Last Stay",
          "lastStayYear": "Last Stay",
          "onPageLeadCampaign": "A+ MARKETING AUTOMATION On-Page Lead Campaign",
          "referralMarketingLeadCampaign": "A+ MARKETING AUTOMATION Referral Lead Campaign",
          "region": "Region",
          "stayDuration": "Duration of Stay",
          "stays": "Stays",
          "tiktokLeadCampaign": "TikTok Lead Campaign",
          "travelMotivations": "Travel Motives",
          "tripGroup": "Travel Group"
        },
        "title": "Segments"
      },
      "title": "People",
      "unsubscribeDialog": {
        "description": "The email address will be unsubscribed from all marketing emails and added to the exclusion list.",
        "title": "Unsubscribe person"
      }
    },
    "reports": {
      "ageGroupDistribution": {
        "title": "Guest Distribution by Age",
        "tooltip": "This table shows the distribution of all guests of the respective year based on their age."
      },
      "countries": {
        "collapsibleTable": {
          "error": "An unexpected error has occurred. Please try again later."
        },
        "description": "In the following table, all reservations (excl. canceled reservations) are analyzed based on the country of residence of the person making the reservation. The reservations are assigned to the respective year based on the date of arrival.",
        "filter": {
          "ageGroup": {
            "label": "Age of the person making the reservation",
            "placeholder": "All"
          },
          "allAgeGroups": "All age groups",
          "allFrequencies": "All countries",
          "frequency": {
            "label": "Frequency",
            "placeholder": "All"
          },
          "period": {
            "label": "Time Span"
          }
        },
        "title": "Countries"
      },
      "countriesDistribution": {
        "title": "Guest Distribution by Country of Residence",
        "tooltip": "This table shows the distribution of all guests of the respective year based on the country of residence (not nationality) at the time of reservation."
      },
      "cutOfDaysDistribution": {
        "table": {
          "average": "&empty; Number of days",
          "fiveToSixMonths": "121 - 180 days",
          "oneMonth": "0 - 30 days",
          "other": "Other",
          "sixAndMoreMonths": "> 180 days",
          "threeToFourMonths": "61 - 120 days",
          "title": "Booking Lead Time",
          "twoMonths": "31 - 60 days"
        },
        "title": "Distribution by Booking Lead Time",
        "tooltip": "This table shows the distribution of all reservations of the respective year based on how far in advance the reservations were made.<br/>In case the corresponding booking lead time cannot be determined, such as when the reservation was created after the time of arrival, the reservation will be listed under \"other\"."
      },
      "description": "In the following tables, all reservations (excl. canceled reservations) are analyzed from different perspectives and assigned to their corresponding categories. Based on their respective dates of arrival, they are also allocated to their corresponding year. ",
      "filter": {
        "ageGroup": {
          "label": "Age of the reserving person",
          "placeholder": "All"
        },
        "allAgeGroups": "All age groups",
        "allCountries": "All countries",
        "allFrequencies": "All",
        "countries": {
          "label": "Country of residence of the reserving person",
          "placeholder": "All"
        }
      },
      "frequencyDistribution": {
        "table": {
          "firstTimer": "Res. from new guests",
          "frequenter": "Res. from returning guests",
          "regular": "Res. from regular guests",
          "reservationsCount": "Number of reservations",
          "title": ""
        },
        "title": "Distribution by Frequency",
        "tooltip": "This table shows the distribution of all reservations of the respective year based on the booking frequency of the person making the reservation.<br/><br/>Res. from new guests: The person making the reservation has made a reservation for the first time.<br/><br/>Res. from returning guests: The person making the reservation has made reservations before.<br/><br/>Res. from regular guests: The person making the reservation has made at least 3 additional reservations in the period of the previous 4 calendar years up until the time of arrival of this specific reservation."
      },
      "keyIndicators": {
        "table": {
          "arrivalsCount": "Arrivals",
          "averageTurnover": "&empty; Res. revenue",
          "reservationsCount": "Reservations",
          "staysCount": "Overnight stays",
          "title": "",
          "totalTurnover": "Revenue in €"
        },
        "title": "Reservation Key Figures",
        "tooltip": "This table shows all relevant key figures for the reservations of the respective year."
      },
      "lengthOfStayDistribution": {
        "table": {
          "averageNights": "&empty; Number of days",
          "fourToSixNights": "4 - 6 days",
          "oneToThreeNights": "1 - 3 days",
          "sevenAndMoreNights": ">= 7 days",
          "title": "Length of stay"
        },
        "title": "Distribution by Length of Stay",
        "tooltip": "This table shows the distribution of all reservations of the respective year based on the length of stay of the reservation."
      },
      "occupancy": {
        "chart": {
          "cancellations": "from Cancellations",
          "filter": {
            "cancellations": "Cancellations",
            "interval": "Evaluation Interval",
            "intervalValues": {
              "monthly": "Monthly",
              "weekly": "Weekly"
            },
            "noReferenceYear": "No reference year",
            "options": "Future options",
            "referenceDate": "Reference Date",
            "referenceYear": "Reference Year",
            "years": "Years"
          },
          "intro": "With the following analysis tool, the room occupancy of various years can be compared on a monthly and weekly basis. By means of \"limitation date\", the future occupancy can be viewed at the status of a specific day. The \"reference year\" serves to visualize the final occupancy of the selected reference year in the chart.",
          "options": "from Options",
          "referenceYearLabel": "Reference Year {year}",
          "referenceYearLabelShort": "Ref. {year}",
          "reservations": "from Reservations",
          "title": "Room Occupancy"
        },
        "description": "In the following tables, room occupancy is determined based on the number of effective room nights from all reservations for the respective year. If specified in the settings of your ADDITIVE+ ACCOUNT, closure periods and changes in the number of rooms are taken into account.",
        "monthly": {
          "title": "Room Occupancy per Night",
          "tooltip": "This table shows occupancy figures for various years on a monthly basis. For the calculations, effective opening days as well as the actual number of rooms available are considered.<br>1st value: Effective number of occupied rooms during the effective opening days of the month (= occupied room nights).<br>2nd value: Percentage of occupied room nights to room capacity during the effective opening days of the month (= room occupancy)."
        },
        "notification": {
          "button": "Go to settings",
          "missingClosingPeriods": "Please specify your closing periods in the settings of your ADDITIVE+ ACCOUNT so that they can be taken into account for the respective evaluations.",
          "missingRoomsCount": "Please specify the number of rooms in the settings of your ADDITIVE+ ACCOUNT so that they can be taken into account for the respective evaluations.",
          "missingSettings": "Please specify the number of rooms as well as your closing periods in the settings of your ADDITIVE+ ACCOUNT so that these can be taken into account for the respective evaluations."
        },
        "title": "Occupancy",
        "yearly": {
          "title": "Room Occupancy per Year",
          "tooltip": "This table shows occupancy figures on an annual basis. For the calculations, effective opening days as well as the effective number of rooms available are considered.<br>Opening days: number of opening days in the respective calendar year. Closing periods, if specified, and leap years are taken into account.<br>Room capacity: Number of maximum possible occupied rooms in a calendar year. Ongoing changes in the number of rooms, if specified, are taken into account.<br>Occupied room nights: Effective number of occupied rooms in a calendar year.<br>Room occupancy: Percentage of occupied room nights to room capacity."
        }
      },
      "reportCards": {
        "countries": {
          "description": "Go to detailed analysis",
          "title": "Countries"
        },
        "occupancy": {
          "description": "Go to detailed analysis",
          "title": "Occupancy"
        }
      },
      "title": "Reports",
      "travelGroupDistribution": {
        "table": {
          "adultsWithChildren": "1 or 2 adults with child(ren)",
          "averagePersonCount": "&empty; Number of people",
          "group": "Group",
          "oneAdult": "1 adult",
          "other": "Other",
          "title": "Travel groups",
          "twoAdults": "2 adults"
        },
        "title": "Distribution by Travel Groups",
        "tooltip": "This table shows the distribution of all reservations of the respective year based on the composition of the travel group of the reservation.<br/><br/>1 adult: The reservation is valid for 1 adult.<br/><br/>2 adults: The reservation is valid for 2 adults.<br/><br/>1 or 2 adults with child(ren): The reservation is valid for 1 or 2 adults with at least one minor (child or infant from PMS).<br/><br/>Group: The reservation is valid for 3 or more adults with or without one or multiple minor(s) (child or infant from PMS).<br/><br/>Others: The reservation is valid for a constellation of people outside all of the other categories."
      }
    },
    "routes": {
      "auditLog": {
        "title": "Activity Log"
      },
      "dashboard": {
        "title": "Dashboard"
      },
      "exclusionList": {
        "title": "Exclusion List"
      },
      "help": {
        "title": "Help and support"
      },
      "people": {
        "activities": {
          "title": "Activities"
        },
        "title": "People"
      },
      "reports": {
        "countries": {
          "title": "Countries"
        },
        "occupancy": {
          "title": "Occupancy"
        },
        "title": "Reports"
      },
      "settings": {
        "integrations": {
          "empty": {
            "button": "Specify hotel software",
            "info": "No hotel software defined. To establish the data transfer to the various apps, you must first specify your hotel software in the settings of your ADDITIVE+ ACCOUNT. Alternatively, you can also import the data manually in the \"People\" menu item without having to establish a connection to your hotel software."
          },
          "title": "Integrations"
        },
        "title": "Settings"
      }
    },
    "select": {
      "empty": "No option found",
      "emptyOption": "No selection",
      "noResults": "No results found",
      "searchPlaceholder": "Search"
    },
    "selectContainer": {
      "items": "{count, plural, =1 {# element} other {# elements}} selected"
    },
    "settings": {
      "connection": {
        "aida": {
          "description": "Import guest and reservation data from Aida.",
          "title": "Aida Data Import"
        },
        "apaleo": {
          "description": "Import guest and reservation data from Apaleo.",
          "title": "Apaleo Data Import"
        },
        "asa": {
          "description": "Import guest and reservation data from ASA Hotel.",
          "instructions": {
            "description": "The interface is ready for imports from ASA Hotel and will accept these in the future.<br/>To set up the data import in ASA Hotel, please follow the instructions.",
            "download": "Download guide",
            "title": "Import enabled"
          },
          "title": "ASA Hotel Data Import"
        },
        "casablanca": {
          "description": "Import guest and reservation data from Casablanca Hotel.",
          "title": "Casablanca Data Import"
        },
        "elite": {
          "description": "Import guest and reservation data from Elite hotel software.",
          "title": "Elite Data Import"
        },
        "fidelio": {
          "description": "Import guest and reservation data from Fidelio hotel software.",
          "title": "Fidelio Data Import"
        },
        "gastrodat": {
          "description": "Import guest and reservation data from Gastrodat.",
          "title": "Gastrodat Data Import"
        },
        "guestline": {
          "description": "Import guest and reservation data from Guestline.",
          "title": "Guestline Data Import"
        },
        "hotel_felix": {
          "description": "Import guest and reservation data from Hotel Felix.",
          "title": "Hotel Felix Data Import"
        },
        "hugo": {
          "description": "Import guest and reservation data from Hugo.",
          "title": "Hugo Data Import"
        },
        "mews": {
          "description": "Import guest and reservation data from Mews hotel software.",
          "title": "Mews Data Import"
        },
        "protel": {
          "description": "Import guest and reservation data from Protel.",
          "title": "Protel Data Import"
        },
        "si_hot": {
          "description": "Import guest and reservation data from Sihot.",
          "title": "Sihot Data Import"
        },
        "status": {
          "false": {
            "message": "Most recent import: {date}",
            "noImportMessage": "No import has been performed yet.",
            "state": "error"
          },
          "true": {
            "message": "Most recent import: {date}",
            "state": "success"
          },
          "undefined": {
            "message": "The data import has either not been established correctly nor not been established at all.",
            "state": ""
          }
        },
        "warning": {
          "action": "Disable",
          "description": "Are you sure you want to disable ASA Hotel data import?",
          "title": "Disable data import"
        },
        "win_hotel": {
          "description": "Import guest and reservation data from WinHotel.",
          "title": "WinHotel Data Import"
        }
      },
      "facebookLeads": {
        "facebookOAuth": {
          "connect": "Connect",
          "connectedPopover": "User {user} connected",
          "disconnect": "Disconnect",
          "disconnectDialog": {
            "description": "Are you sure you want to disconnect your Facebook account?",
            "title": "Disconnect"
          },
          "disconnectedPopover": "Not connected",
          "facebookConnect": {
            "description": "Connect to Facebook in order to use your own profile",
            "title": "Connect to Facebook"
          },
          "label": "Facebook",
          "loginAbortDialog": {
            "description": "Are you sure you want to discard these settings? If you proceed, your Facebook Account will not be connected.",
            "title": "Discard settings"
          },
          "systemUser": {
            "description": "Uses the default ADDITIVE+ System User",
            "title": "Connect as System User"
          }
        },
        "facebookPage": {
          "label": "Facebook Page"
        },
        "importLeads": "Import Facebook leads",
        "label": "Facebook Lead Import",
        "missingPermissionsDialog": {
          "description": "Please grant all requested permissions in the following window. Otherwise \"Facebook lead import\" cannot be enabled.",
          "error": "Not all required permissions were granted.",
          "title": "Missing permissions"
        }
      },
      "import": {
        "skipDeletedReservationsImport": {
          "description": "Reservations marked as \"deleted\" by data import remain stored in your ADDITIVE+ CRM.",
          "label": "Keep deleted reservations",
          "modalDescription": "Should the reservations marked as \"deleted\" {active, select, true {be deleted from ADDITIVE+ CRM} other { remain stored in ADDITIVE+ CRM}}?",
          "modalTitle": "Keep deleted reservations",
          "warning": "ATTENTION: This setting should only be necessary in exceptional cases!"
        },
        "title": "Exceptions"
      },
      "tiktokLeads": {
        "error": {
          "description": "An unexpected error occurred. Please log in to your TikTok account again.",
          "title": "Error"
        },
        "importLeads": "Import TikTok leads",
        "label": "TikTok Lead Import",
        "noAdAccountsInfo": "No ad accounts were found. In order to enable \"TikTok lead import\", at least one account must be created in your TikTok Business Center.",
        "tiktokAdAccount": {
          "label": "TikTok Ad Account"
        },
        "tiktokOAuth": {
          "connect": "Connect",
          "connectedPopover": "User {user} connected",
          "disconnect": "Disconnect",
          "disconnectDialog": {
            "description": "Are you sure you want to disconnect your TikTok account?",
            "title": "Disconnect"
          },
          "disconnectedPopover": "Not connected",
          "label": "TikTok",
          "loading": "Loading..."
        }
      }
    },
    "toast": {
      "success": "Action was successful",
      "unexpectedError": "An unexpected error has occurred"
    },
    "toasts": {
      "loading": {
        "description": "Please hold on, we're still loading!",
        "title": "This is taking longer than expected."
      }
    },
    "uiActions": {
      "back": "Back",
      "close": "Close",
      "confirm": "Confirm",
      "delete": "Delete",
      "edit": "Edit",
      "export": "Export",
      "info": "Informations",
      "menu": "Menu",
      "more": "More",
      "reset": "Reset",
      "save": "Save",
      "search": "Search"
    },
    "uiApplicationNavigationDrawer": {
      "accountSwitch": {
        "empty": "No organization found",
        "organizationSettings": "Organization settings",
        "searchPlaceholder": "Search"
      },
      "appsList": {
        "goToApps": "Go to apps",
        "myApps": "My apps",
        "planNames": {
          "10": {
            "starter_yearly": "Standard"
          },
          "12": {
            "professional_yearly": "Segmentation",
            "starter_yearly": "Customer Data Platform"
          },
          "50": {
            "enterprise_yearly": "MARKETING CLOUD",
            "professional_yearly": "AUTOMATION CLOUD",
            "starter_yearly": "LEAD CLOUD"
          },
          "51": {
            "professional_yearly": "Attribution",
            "starter_yearly": "Tracking"
          },
          "default": {
            "enterprise_monthly": "Enterprise",
            "enterprise_yearly": "Enterprise",
            "professional_monthly": "Professional",
            "professional_yearly": "Professional",
            "standard": "Standard",
            "starter_monthly": "Starter",
            "starter_yearly": "Starter"
          }
        },
        "showMoreApps": "Discover other apps"
      }
    },
    "uiCategoryCard": {
      "contentCountError": "This category cannot be deleted, because it still contains content.",
      "titleError": "Attention!"
    },
    "uiCollapsibleTable": {
      "collapsibleRowDescription": "{count, plural, =1 {# subcategory} other {# subcategories}}"
    },
    "uiColorComposition": {
      "dialog": {
        "preview": "Preview",
        "save": "Save"
      }
    },
    "uiContentCount": {
      "count": "{count, plural, =1 {# entry} other {# entries}}",
      "filterCount": "{count, plural, =1 {# entry} other {# entries}} found",
      "pages": "Page <span class=\"primary ph05\">{page}</span> / <span class=\"pl05\">{total}</span>"
    },
    "uiContentInfo": {
      "showLess": "Show less",
      "showMore": "Show more"
    },
    "uiDateFilter": {
      "label": "Observation period"
    },
    "uiDatePicker": {
      "filters": {
        "custom_date": "Custom date",
        "last_month": "Last month",
        "last_thirty_days": "Last 30 days",
        "last_week": "Last week",
        "this_month": "This month",
        "this_week": "This week"
      },
      "weekDaysShort": {
        "Fri": "Fri",
        "Mon": "Mon",
        "Sat": "Sat",
        "Sun": "Sun",
        "Thu": "Thu",
        "Tue": "Tue",
        "Wed": "Wed"
      }
    },
    "uiDatetimePicker": {
      "date": "Date",
      "endDate": "End date",
      "endTime": "End time",
      "error": {
        "beforeToday": "Past date",
        "false": "Invalid date"
      },
      "startDate": "Start date",
      "startTime": "Start time",
      "time": "Time"
    },
    "uiDiscardChanges": {
      "discardAction": "Discard",
      "message": "Do you want to discard the unsaved changes?",
      "title": "Attention!"
    },
    "uiEditor": {
      "actions": {
        "bold": "Bold",
        "bulletList": "List",
        "code": "Code",
        "headings": {
          "h1": "Heading 1",
          "h2": "Heading 2",
          "h3": "Heading 3",
          "h4": "Heading 4",
          "p": "Normal"
        },
        "headingsShort": {
          "h1": "H1",
          "h2": "H2",
          "h3": "H3",
          "h4": "H4",
          "p": "P"
        },
        "horizontalRule": "Horizontal line",
        "italic": "Italic",
        "link": "Link",
        "numberedList": "Numbered list",
        "quote": "Quote",
        "underline": "Underline"
      },
      "urlDialog": {
        "openInNewTab": "Open in new tab",
        "save": "Save",
        "text": "Text",
        "type": {
          "name": "Link type",
          "options": {
            "email": "Email",
            "tel": "Phone",
            "weblink": "Web link"
          }
        },
        "url": "URL",
        "urlNotValid": {
          "email": "This email address is not valid",
          "tel": "This phone number is not valid",
          "weblink": "This URL is not valid"
        }
      }
    },
    "uiFilterInputs": {
      "buttonText": "Filter",
      "daterange": {
        "end": "until",
        "start": "from"
      }
    },
    "uiFormControl": {
      "recommendedLengthWarning": "Value exceeds the recommended length of {recommendedLength} characters"
    },
    "uiFormDialog": {
      "save": "Save"
    },
    "uiListView": {
      "bulkDelete": "{count, plural, =1 {One element} other {# elements}} deleted",
      "common": {
        "bulkDelete": {
          "action": "Delete all",
          "description": "The selected contents will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete the selected contents?"
        },
        "deleteContent": {
          "description": "The content will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete <span class=\"font-medium\">\"{name}\"</span>?",
          "title": "Attention!"
        }
      },
      "delete": "Delete",
      "empty": "There are no existing contents.<br/>Click the button below to create a new content.",
      "error": "An unexpected error occurred.",
      "errors": {
        "rate_in_use": "This rate cannot be deleted, since it is used in a price timespan.",
        "title": "Attention!",
        "used_in_post": "This person cannot be deleted, since it is used in a post.",
        "vouchers_left": "This content cannot be deleted, since it is used in a voucher."
      },
      "noMatch": "Your search did not return any results.",
      "properties": {
        "published": "published",
        "unpublished": "unpublished"
      }
    },
    "uiMonthDayPicker": {
      "day": "Day",
      "month": "Month"
    },
    "uiNavigationBarFilterabel": {
      "dynamicFilters": {
        "buttonText": "More filters",
        "error": {
          "retryButtonText": "An error has occurred! Try again now"
        },
        "navigationDrawer": {
          "title": "More filters"
        }
      },
      "searchInputDefaultPlaceholder": "Search"
    },
    "uiOnboarding": {
      "actions": {
        "back": "Back",
        "endOnboarding": "Get started now",
        "next": "Next",
        "skip": "Skip"
      }
    },
    "uiPublishResource": {
      "description": "Choose which language you would like to publish. You can also limit the time frame for the publication.",
      "descriptionNoLang": "Select the time to publish the content.",
      "errors": {
        "after": "Must be after start date",
        "before": "Must be before end date",
        "required": "Required"
      },
      "languages": "Languages",
      "limitTime": "Limit the time frame",
      "save": "Save",
      "settings": "Settings",
      "title": "Publish"
    },
    "uiRangeSlider": {
      "errorMessage": "The value must be a positive integer",
      "max": "max",
      "min": "min"
    },
    "uiTable": {
      "empty": "No data available",
      "error": "An error has occurred",
      "retry": "Try again",
      "showLess": "Show less",
      "showMore": "Show more"
    },
    "uiTags": {
      "error": "This value has already been added",
      "interests": {
        "interests": "Interests",
        "travelMotivation": "Travel motives",
        "travelTime": "Seasons"
      },
      "placeholder": "Add tag",
      "tags": "Tags"
    },
    "uiTimepicker": {
      "errorMessage": "Invalid time value",
      "timeUnit": ""
    },
    "uiToast": {
      "success": "Action completed successfully",
      "unexpectedError": "An unexpected error has occurred"
    }
  }]];
});